/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.scss";

import ReactDOM from "react-dom/client";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

// Replace this by your own page wrapper:

export const onInitialClientRender = () => {
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    // const languageFromStorage = localStorage.getItem("gatsby-i18next-language");
    // const browserLanguage = navigator.language;
    // const shortBrowserLang = browserLanguage.split("-")[0];
    // if (window.location.pathname === "/") {
    //   if (languageFromStorage) {
    //     if (languageFromStorage === "tr") {
    //       window.location.replace("/tr/");
    //     }
    //   } else if (shortBrowserLang === "tr") {
    //     window.location.replace("/tr/");
    //   }
    // }
  }
};
